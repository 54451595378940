@import '../../../node_modules/bootstrap/scss/functions';
@import '../../../node_modules/bootstrap/scss/variables';
@import '../../../node_modules/bootstrap/scss/mixins';

.yugafu-drink-img {
  width: 30%;
}

.review-container {
  color: white;
  text-align: center;
  position: relative;
}

.review-box {
  background-color: transparent;
  color: $white;
  padding: 2em 7em;
  margin: auto;
  max-width: 768px;
  position: relative;

  height: 600px;
}

.review-text {
  line-height: 28px;
  font-size: 15px;
  text-align: center;
}

.fa-icon {
  color: rgba(255, 255, 255, 0.3);
  position: absolute;
  top: 2em;

  &.right {
    right: 3rem;
  }

  &.left {
    left: 3rem;
  }
}

.stars-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.stars {
  margin-right: 0.5em;
}

.progress-bar {
  background-color: white;
  margin-bottom: 2em;
  height: 0.2em;
  width: 100%;
  animation: grow 10s linear infinite;
  transform-origin: left;
}

.review-fade {
  animation: review-fade 10s linear infinite;
}

.copyright-text {
  padding: 10px;
  float: right;
}

@keyframes grow {
  0% {
    transform: scaleX(0);
  }
}

@keyframes review-fade {
  0%,
  100% {
    opacity: 0;
  }
  5%,
  95% {
    opacity: 1;
  }
}

@media only screen and (max-width: 576px) {
  .fa-icon {
    color: rgba(255, 255, 255, 0.3);
    position: absolute;
    top: 2em;

    &.right {
      right: 1rem;
    }

    &.left {
      left: 1rem;
    }
  }

  .review-box {
    padding: 3em;
  }
}
