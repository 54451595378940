@import url('https://fonts.googleapis.com/css2?family=Bitter&display=swap');

body {
  /* font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; */
  font-family: 'Bitter', serif;
  background-color: black;
}

.fixed-header {
  color: white;
  background: black;
  position: fixed;
  top: 0;
  text-align: center;
  padding: 0.5rem 0px;
  font-size: 15px;
  opacity: 0.75;
  font-weight: bold;
  z-index: 999;
  width: 100vw;
}

a {
  color: white;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

::-webkit-scrollbar {
  width: 0; /* Remove scrollbar space */
  background: transparent; /* Optional: just make scrollbar invisible */
}
