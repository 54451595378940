.reservation-warpper {
  padding: 10%;

  .reservation-title {
    padding-bottom: 40px;
  }
}

// "sm" break point
@media screen and (max-width: 576) {
  .reservation-warpper {
    padding: 0 0 40px;
  }
}