@import '../../../node_modules/bootstrap/scss/functions';
@import '../../../node_modules/bootstrap/scss/variables';
@import '../../../node_modules/bootstrap/scss/mixins';

.banner-container {
  color: $white;
  height: 100vh;
  width: 100%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: rgba(27, 26, 27, 0.548);
  padding: 0px;

  a:hover {
    background-color: $white;
    color: $purple-700;
  }
}
.title-container {
  position: relative;
  width: 75%;
  margin: auto;
  padding-top: 10%;
  pointer-events: none;
  user-select: none;
  display: flex;
}

.title-text {
  user-select: none;
  position: absolute;
  top: 45px;
  left: 1em;
}

.banner-content {
  position: absolute;
  left: 1em;
  bottom: 1em;
}

.sns-icons {
  margin-bottom: 1em;
  padding-left: 0.5em;
}

.banner-text {
  color: $white;
  padding: 0.2em 0.5em;
  text-decoration: none;
  font-size: 16px;
  transition: 0.3s;
  border-radius: 10px;
}

.banner-bold {
  font-weight: bold;
  font-size: 20px;
}

@media screen and (max-height: 600px) {
  .title-container {
    display: none;
  }
}
