.about {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 10%;
}

.about-text {
  display: block;
  font-size: 15px;

  h4 {
    padding: 10px 0px;
  }

  p {
    padding: 10px 0px;
  }

  .quote {
    font-size: 16px;
    font-style: italic;
    text-align: center;
  }
}

.about-img {
  justify-self: center;
  height: 25vh;
  margin-bottom: 10%;
}

@media only screen and (max-width: 700px) {
  .about-img {
    display: none;
  }
}

// "sm" break point
@media screen and (max-width: 576) {
  .about {
    margin: 0;
  }
}
