@import '../../../node_modules/bootstrap/scss/functions';
@import '../../../node_modules/bootstrap/scss/variables';
@import '../../../node_modules/bootstrap/scss/mixins';

.menu-container {
  text-align: center;
}

.menu-gallery-img {
  height: 30vh;
  margin: 2rem;
}

.menu-gallery-text {
  padding: 1rem;
  font-size: 16px;

  a {
    margin: 0px 2px;
    padding: 0px 3px;
  }

  a:hover {
    background-color: $white;
    color: $purple-700;
    transition: 0.3s;
  }
}

.gallery-row {
  height: 40vh;
  display: flex;
  align-items: bottom;
  justify-content: center;
}
