@import '../../../node_modules/bootstrap/scss/functions';
@import '../../../node_modules/bootstrap/scss/variables';
@import '../../../node_modules/bootstrap/scss/mixins';

$bg-flower: url('../../assets/logo_background_v1.png');
$banner1: url('../../assets/banner_1.jpg');
$banner2: url('../../assets/banner_2.jpg');

.content-container {
  // background-color: $purple-800;
  // background-image: $bg-flower;
  // background-position: center;
  // background-size: 45%;
  // background-repeat: no-repeat;
  // background-attachment: fixed;
  color: white;

  overflow-y: scroll;
  overflow-x: hidden;
  padding: 0px;
}

.bg-flower {
  background-color: $purple-800;
  background-image: $bg-flower;
  background-position: 75%;
  background-size: 30%;
  background-repeat: no-repeat;
  background-attachment: fixed;
}

.carousel-container {
  height: 100%;
  padding: 0px;
  position: fixed;
}

.yugafu-banner {
  height: 100vh;
}

.text-fade {
  animation: text-fade 4s;
}

@keyframes text-fade {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

// "md" break point
@media screen and (max-width: 768px) {
  .carousel-container {
    height: 100%;
    padding: 0px;
    position: relative;
  }

  .bg-flower {
    background-size: 80%;
    background-position: center;
  }
}
